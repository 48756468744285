import { Component } from '@angular/core';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent {
    title = 'psychotherapie-halle';

    config: any;
    fullpage_api: any;

    constructor() {
        this.config = {
            licenseKey: 'B9783C16-E38C42E7-B2022915-512AD706',
            anchors: ['home'],
            sectionsColor: ['transparent'],
            menu: '#menu',
            controlArrows: false,
            scrollBar: false,
            navigation: false,
            slidesNavigation: false
        };
    }

    getRef(fullPageRef) {
      this.fullpage_api = fullPageRef;
    }
}
