import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageImpressumComponent } from './page-impressum/page-impressum.component';
import { PageDatenschutzComponent } from './page-datenschutz/page-datenschutz.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { MenuComponent } from './menu/menu.component';

import { AngularFullpageModule } from '@fullpage/angular-fullpage';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { CookieLawModule } from 'angular2-cookie-law';

@NgModule({
  declarations: [
    AppComponent,
    PageImpressumComponent,
    PageDatenschutzComponent,
    PageHomeComponent,
    MenuComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFullpageModule,
    // CookieLawModule 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
