<div class="fp-bg"></div>
<div class="card container centered">
    <div id="logo"><img src="assets/img/logo.png"></div>
    <h1>Praxis f&uuml;r Psychotherapie</h1>
    <h2>Dipl.-Psych. Ulrike Richter</h2>
    <hr>
    <h3>Psychologische Psychotherapeutin (VT)</h3>
    <p>
        Behandlung von Erwachsenen sowie Kindern &amp; Jugendlichen<br>
        alle Kassen und privat<br>
        Einzel- &amp; Gruppentherapie
    </p>
    <p class="width _40">
        Leipziger Str. 64<br>
        06108 Halle/Saale
    </p>
    <p class="width _60">
        Tel: <a href="tel:+4934568233387">+49(0) 345 68233387</a><br>
        Mail: <a href="mailto:u.richter@psychotherapie-halle.info">u.richter@psychotherapie-halle.info</a>
    </p>
    <p>
        Tel. Sprechzeiten: Di. 13:00 - 13:50 Uhr und Mi. 9:00 - 09:50 Uhr
    </p>

    <app-menu class="centered menu mobile"></app-menu>
</div>
<br>
<app-menu class="card container centered menu large"></app-menu>