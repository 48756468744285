<div fullpage id="fullpage" [options]="config" (ref)="getRef($event)">

    <div class="section">
        <app-page-home class="slide" data-anchor="home"></app-page-home>
        <app-page-impressum class="slide" data-anchor="impressum"></app-page-impressum>
        <app-page-datenschutz class="slide" data-anchor="datenschutz"></app-page-datenschutz>
    </div>

    
</div>
<!-- <cookie-law></cookie-law> -->